import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import "../data/sections-fragment";
export const query = graphql`
  query {
    mdx(slug: { eq: "fertiggaragen" }) {
      slug
      frontmatter {
        title
        footer {
          showContactFooter: show_contact_footer
          showContactColumn: show_contact_column
        }
        backgrounds {
          ...BackgroundFragment
        }
        sections {
          ...MdxFrontmatterSectionsFields
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Fertiggaragen",
  "backgrounds": [{
    "rows": "1 / 4",
    "gradient": "linear-gradient(to-b, gray.50, rgba(255,255,255,0))",
    "spacing": {
      "top": ["33vh", null, "35vh", null, "45vh"]
    }
  }],
  "sections": [{
    "type": "header",
    "header_type": "beta",
    "title": "Fertiggaragen",
    "text": "Manchmal reichen einfache Lösungen. Auch Fertiggaragen vom Fachmann können gute, kostengünstige Lösungen sein. Wir liefern Ihnen diese Varianten fix fertig auf Ihr Grundstück. Dabei haben Sie die Wahl zwischen Einzel,- Doppel oder Reihengaragen in verschiedenen Standard-Maßen.",
    "image": {
      "src": "images/header-fertiggaragen.jpg",
      "alt": "Fertiggaragen",
      "fit": "cover",
      "position": "50% 50%"
    }
  }, {
    "type": "advantages",
    "slug": "vorteile",
    "title": "Vorteile",
    "spacing": {
      "top": ["20", null, "32"],
      "bottom": ["40", null, "72"]
    },
    "text": "Eine bewährte Systembautechnik sichert viele Ausstattungsvarianten. Und so ist auch eine Fertiggarage aus Beton vom Fachmann eine stabile und schnelle Lösung, die einen Vorteil hat – der günstige Preis. Durch die industrielle Fertigung ist die Herstellung günstiger und sind die Bauzeiten kürzer.",
    "advantages": "* Bewährte Fertiggaragen von unserem langjährigen Partner\n* Eine günstige Lösung, die sich dann eignet, wenn keine Besonderheiten zu berücksichtigen sind\n* Eine Umsetzung zum Festpreis, die Ihnen finanzielle Sicherheit gibt\n* Eine schnelle, fachgerechte Ausführung\n* Ein Team von zuverlässigen Facharbeitern mit langjähriger Berufserfahrung"
  }, {
    "type": "sizes",
    "slug": "groessen",
    "title": "Größen",
    "spacing": {
      "bottom": ["40", null, "72"]
    },
    "sizes": [{
      "title": "Einzelgarage",
      "widths": "2,68 — 3,48 m",
      "lengths": "5,30 — 7,00 m",
      "image": {
        "src": "images/einzelgarage.svg",
        "alt": "Einzelgarage"
      }
    }, {
      "title": "Doppelgarage",
      "widths": "5,36 - 6,00 m",
      "lengths": "5,30 — 7,00 m",
      "image": {
        "src": "images/doppelgarage.svg",
        "alt": "Doppelgarage"
      }
    }, {
      "title": "Reihengarage",
      "widths": "2,68 — 2,98 m",
      "lengths": "5,30 — 7,00 m",
      "image": {
        "src": "images/reihengarage.svg",
        "alt": "Reihengarage"
      }
    }, {
      "title": "Großraumgarage",
      "widths": "6,00 m",
      "lengths": "6,00 m",
      "heights": "2,72 m",
      "image": {
        "src": "images/grossraumgarage.svg",
        "alt": "Großraumgarage"
      }
    }]
  }, {
    "type": "details",
    "slug": "details",
    "title": "Details",
    "text": "Der Fokus auf das Detail macht das große Ganze erst rund. In unserer Planung legen wir Wert auf jede kleine Besonderheit, jede Facette, die zu einem perfekten Ergebnis beiträgt. Wir haben alles im Blick, was notwendig ist, um Ihre Wunschgarage zu bauen.",
    "background_image": {
      "src": "images/beton.jpg"
    },
    "details": ["src/data/garages/details/vordach.yaml", "src/data/garages/details/unterkellerung.yaml", "src/data/garages/details/carport-stuetzen.yaml", "src/data/garages/details/lueftungsoeffnungen.yaml", "src/data/garages/details/dachbegruenung.yaml", "src/data/garages/details/satteldach.yaml", "src/data/garages/details/dachbekiesung.yaml"]
  }, {
    "type": "colors",
    "slug": "farbgestaltung",
    "title": "Farbgestaltung für Sektionaltore und Nebentüren",
    "spacing": {
      "top": ["32", null, "40", null, "48", null, "64"],
      "bottom": ["32", null, "40", null, "48", null, "64"]
    },
    "text": "Die doppelwandigen LPU-Sektionaltore und Neben- türen erhalten Sie serienmäßig in Verkehrsweiß RAL 9016 sowie zusätzlich in 13 preiswerten Super-Color- Farben und rund 200 Farben nach RAL* sowie in vielen NCS und DB Farben.",
    "color_groups": "src/data/garages/colors/colors.yaml"
  }, {
    "type": "faqs",
    "slug": "wissenswertes",
    "title": "Wissenswertes",
    "faqs": ["src/data/garages/faqs/bauantrag.yaml", "src/data/garages/faqs/unterkellern.yaml", "src/data/garages/faqs/carport.yaml", "src/data/garages/faqs/terasse.yaml", "src/data/garages/faqs/versetzen.yaml", "src/data/garages/faqs/bauen.yaml", "src/data/garages/faqs/kosten.yaml"]
  }]
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      